import "./InputControl.scss";

function InputControl({ id, name, placeholder, iconURL, value, onChange, theme = "orange", textArea = false }) {
    const classList = ["ev-comp", "ev-comp-input-control", `theme-${theme}`]
    textArea && classList.push("input-textarea");
    return (
        <div className={classList.join(" ")}>
            <div className="icon">
                <img height={20} width={20} src={iconURL} alt="input icon" />
            </div>
            {
                textArea &&
                <textarea type="text" id={id} name={name} className="input text-style-input" placeholder={placeholder} value={value} rows="8" onChange={onChange} ></textarea>
            }
            {
                !textArea &&
                <input type="text" id={id} name={name} className="input text-style-input" placeholder={placeholder} value={value} onChange={onChange} />
            }
        </div>
    )
}

export default InputControl