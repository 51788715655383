import "./PhotoCard.scss"

function PhotoCard({ theme = "blue", photoURL, title, paragraph }) {
    const classList = ["ev-comp", "ev-comp-photo-card", `theme-${theme}`]
    return (
        <div className={classList.join(' ')}>
            <div className="photo">
                <img height={80} src={photoURL} alt="service" />
            </div>
            <div className="title text-style-card-title">{title}</div>
            <div className="paragraph text-style-card-paragraph">{paragraph}</div>
        </div>
    )
}

export default PhotoCard