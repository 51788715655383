import "./IllustrationCard.scss"
function IllustrationCard({ theme = "blue", illustrationURL, title, paragraph }) {
    const classList = ["ev-comp", "ev-comp-illustration-card", `theme-${theme}`]
    return (
        <div className={classList.join(' ')}>
            <div className="illustration">
                <img height={80} src={illustrationURL} alt="card illustration" />
            </div>
            <div className="title text-style-card-title">{title}</div>
            <div className="paragraph text-style-card-paragraph">{paragraph}</div>
        </div>
    )
}

export default IllustrationCard