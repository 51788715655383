import Button from "./Button"
import InputControl from "./InputControl"
import SelectControl from "./SelectControl"
import Spacer from "./Spacer"
import { useState } from "react"
import "./HubspotForm.scss"

function HubspotForm({ theme, formData, hsForm }) {

    const classList = ["ev-comp-hubspot-form", `theme-${theme}`];

    const formKeys = formData.reduce((ob, data) => ({ [data.key]: "", ...ob }), {});

    const [formFields, setFormFields] = useState(formKeys);
    const [formSubmitText, setFormSubmitText] = useState("Envoyer");

    function onFormSubmit() {
        const data = JSON.stringify({
            "fields": Object.entries(formFields).map(([key, value]) => (
                {
                    "objectTypeId": "0-1",
                    "name": key,
                    "value": value
                }
            )),
            "context": {
                "pageUri": "epavite.fr",
                "pageName": "Landing page"
            }
        });

        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                const message = JSON.parse(this.responseText).inlineMessage;
                if (message) {
                    const el = document.createElement("div");
                    el.innerHTML = message;
                    setFormSubmitText(el.innerText);
                    setTimeout(() => {
                        setFormSubmitText("Envoyer");
                    }, 3000);
                }
                message && setFormFields(formKeys);
            }
        });

        xhr.open("POST", `https://api.hsforms.com/submissions/v3/integration/submit/${hsForm.portalId}/${hsForm.formId}`);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.withCredentials = false;
        xhr.send(data);
    }

    return (
        <div className={classList.join(" ")}>
            <Spacer height={40} />
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                {formData.map((data, index) => {
                    switch (data.type) {
                        case "text":
                            return <InputControl key={index} iconURL={`assets/icons/icon-${data.icon}-${theme}.svg`} theme={theme} placeholder={data.title} value={formFields[data.key]} onChange={(e) => { setFormFields((prev) => ({ ...prev, [data.key]: e.target.value })) }} />
                        case "textarea":
                            return <InputControl textArea={true} key={index} iconURL={`assets/icons/icon-${data.icon}-${theme}.svg`} theme={theme} placeholder={data.title} value={formFields[data.key]} onChange={(e) => { setFormFields((prev) => ({ ...prev, [data.key]: e.target.value })) }} />
                        case "select":
                            return <SelectControl key={index} iconURL={`assets/icons/icon-${data.icon}-${theme}.svg`} theme={theme} placeholder={data.title} value={formFields[data.key]} onChange={(e) => { setFormFields((prev) => ({ ...prev, [data.key]: e.target.value })) }} options={data.options} />

                        default:
                            return ""
                    }
                })}
            </div>
            <Spacer height={40} />
            <Button title={formSubmitText} theme={theme} onClick={onFormSubmit} />
            <Spacer height={140} />
        </div>
    )
}

export default HubspotForm