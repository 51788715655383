// import Components from './pages/Components';
import LandingPageHome from './pages/LandingPageHome';
import LandingPageEpa from './pages/LandingPageEpa';
import LandingPageDepRem from './pages/LandingPageDepRem';
import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './pages/sections/Footer';
import Colors from './pages/sections/Colors';
import Markdown from 'react-markdown'
import posts from './posts/posts';
import SectionContainer from './components/SectionContainer';
import Spacer from './components/Spacer';
import TopBar from './pages/sections/TopBar';
import FormSection from './pages/sections/FormSection';
import Button from './components/Button';

function App() {

  const mdComponents = {
    h1({ children }) {
      return <h1 className='text-style-big-header' style={{ textTransform: "uppercase" }}>{children}</h1>
    },
    h2({ children }) {
      return <h2 className='text-style-header' style={{ color: "var(--blue-dark)" }}>{children}</h2>
    },
    p({ children }) {
      return <p style={{ color: "var(--black-opacity-70)" }}>{children}</p>
    }
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* home */}
          <Route path="/" exact element={
            <>
              <TopBar />
              <LandingPageHome theme="orange" />
            </>
          } />

          {/* landing page epaviste */}
          <Route path="/epaves" exact element={
            <>
              <TopBar />
              <LandingPageEpa theme="auburn" />
            </>
          } />

          {/* landing page depannage-remorquage */}
          <Route path="/depannage-remorquage" exact element={
            <>
              <TopBar />
              <LandingPageDepRem theme="metalic-seaweed" />
            </>
          } />

          {/* mentions legales */}
          <Route path="/mentions-legales" exact element={<>
            <TopBar hasBackground={true} />
            <SectionContainer color='blue'>
              <Spacer height={80} />
              <div className="ev-comp-md-container">
                <Markdown components={mdComponents}>{posts[0].content}</Markdown>
              </div>
              <Spacer height={140} />
            </SectionContainer>
          </>} />

          {/* politique de confidentialite */}
          <Route path="/politique-de-confidentialite" exact element={<>
            <TopBar hasBackground={true} />
            <SectionContainer color='blue'>
              <Spacer height={80} />
              <div className="ev-comp-md-container">
                <Markdown components={mdComponents}>{posts[1].content}</Markdown>
              </div>
              <Spacer height={140} />
            </SectionContainer>
          </>} />

          {/* cgu */}
          <Route path="/cgu" exact element={<>
            <TopBar hasBackground={true} />
            <SectionContainer color='blue'>
              <Spacer height={80} />
              <div className="ev-comp-md-container">
                <Markdown components={mdComponents}>{posts[2].content}</Markdown>
              </div>
              <Spacer height={140} />
            </SectionContainer>
          </>} />

          {/* contact form */}
          <Route path="/contact" exact element={
            <>
              <TopBar hasBackground={true} />
              <FormSection theme="blue"
                hsForm={{
                  portalId: "143797396",
                  formId: "a54ffc9d-02b5-4727-8de0-9496f4eeb21f"
                }}
                formData={[
                  { key: "firstname", title: "Prénom", type: "text", icon: "person" },
                  { key: "lastname", title: "Nom", type: "text", icon: 'person' },
                  { key: "email", title: "Adresse mail", type: "text", icon: "mail" },
                  { key: "message", title: "Message", type: "textarea", icon: "letter" },
                ]} >
                <h2 className="text-style-header" style={{ color: "var(--blue-dark)" }}>
                Besoin d'aide en Aquitaine? Remplissez le formulaire ou appelez-nous 24/7 pour une assistance immédiate!
                </h2>
                <Spacer height={40} />
                <a href={"tel: +33536661000"} style={{ display: "block", width: "fit-content" }}>
                  <Button title={"Appelez nous"} theme={"orange"} />
                </a>
                <Spacer height={60} />
                <img style={{ width: "70%" }} src="assets/illustrations/france-aquitaine-map.svg" alt="france map" />
                <Spacer height={80} />
              </FormSection>
            </>
          } />
          <Route path="/colors" exact element={
            <div style={{ padding: "40px" }}>
              <Colors />
              <Colors theme='orange' />
            </div>
          } />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
