import "./SectionTitle.scss";

function SectionTitle({ title, theme = "blue" }) {
    const classList = ["ev-comp", "ev-comp-section-title", `theme-${theme}`]
    return (
        <div className={classList.join(" ")}>
            <div className="title text-style-header">
                <div className="line"></div>
                {title}
            </div>
        </div>
    )
}

export default SectionTitle