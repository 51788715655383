import "./Button.scss"

function Button({ title, size = "big", theme = "blue", onClick }) {
    const classList = ["ev-comp", "ev-comp-button", `theme-${theme}`, `size-${size}`];
    return (
        <div className={classList.join(" ")} onClick={onClick}>
            <div className="title text-style-button">{title}</div>
        </div>
    )
}

export default Button