import "./IconCard.scss"

function IconCard({ theme = "blue", iconURL, title, paragraph }) {

    const classList = ["ev-comp", "ev-comp-icon-card", `theme-${theme}`]
    return (
        <div className={classList.join(' ')}>
            <div className="icon">
                <img height={40} width={40} src={iconURL} alt="card icon" />
            </div>
            <div className="title text-style-card-title">{title}</div>
            <div className="paragraph text-style-card-paragraph">{paragraph}</div>
        </div>
    )
}

export default IconCard