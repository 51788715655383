import "./SelectControl.scss";

function SelectControl({ id, name, options, placeholder, value, iconURL, onChange, theme = "orange" }) {
    const classList = ["ev-comp", "ev-comp-select-control", `theme-${theme}`]
    return (
        <div className={classList.join(" ")}>
            <div className="icon">
                <img height={20} width={20} src={iconURL} alt="input icon" />
            </div>
            <select id={id} name={name} value={value} className="select text-style-input" onChange={onChange}>
                <option value="" disabled>{placeholder}</option>
                {options.map((o, i) => (
                    <option key={i} value={o.value}>{o.title}</option>
                ))}
            </select>
        </div>
    )
}

export default SelectControl