import Button from "../../components/Button"
import SectionContainer from "../../components/SectionContainer"
import Spacer from "../../components/Spacer"
import HubspotForm from "../../components/HubspotForm"

function FormSection({ theme, formData, hsForm, children }) {

    return (
        <section id="section-form">
            <SectionContainer>
                <Spacer height={80} />
                <div className="form-section-box">
                    <div className="content">
                        {children}
                    </div>
                    <div className="form">
                        <HubspotForm theme={theme} formData={formData} hsForm={hsForm}/>
                    </div>
                </div>
            </SectionContainer>
        </section>
    )
}

export default FormSection