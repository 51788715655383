import { useEffect, useState } from "react";
import Nav from "../../components/Nav";
import NavList from "../../components/NavList";

function TopBar({ hasBackground = false }) {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [classList, setClassList] = useState(["nav-container"]);
    const [scrolled, setScrolled] = useState(false);
    const linksData = [
        { title: "Enlèvement d'épaves", href: "/epaves" },
        { title: "dépannage-remorquage", href: "/depannage-remorquage" },
        { title: "contact", href: "/contact" },
    ]

    hasBackground && classList.push("has-background");
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScrolled(!!(window.scrollY));
            window.scrollY && setClassList(["nav-container", "has-background", "scrolled"]);
            !(window.scrollY) && !hasBackground && setClassList(["nav-container"]);
            !(window.scrollY) && hasBackground && setClassList(["nav-container", "has-background"]);
        })
    }, [hasBackground, classList])

    return (
        <>
            <div className={classList.join(" ")}>
                <div className="nav">
                    <a href="/" className="logo">
                        <img height={20} src="assets/logo/ev-logo-white-orange.svg" alt="logo" />
                    </a>
                    <Nav linksData={linksData} />
                    <div className={"burger" + (isNavOpen ? " open" : "")} onClick={() => { setIsNavOpen((p) => !p); }}>
                        <div className="line line-1"></div>
                        <div className="line line-2"></div>
                        <div className="line line-3"></div>
                    </div>
                </div>
                {isNavOpen &&
                    <div className="list">
                        <NavList linksData={linksData} />
                    </div>
                }
            </div>
            {hasBackground && <div style={{ transition: "height 200ms ease-out", height: scrolled ? "60px" : "90px" }}></div>}
        </>
    )
}

export default TopBar