
function GridCell({size = 1, children, style }) {
    const classList = ["ev-comp-grid-cell"];
    return (
        <div className={classList.join(" ")} style={{
            "--grid-cell-span": size,
            ...style
        }}>{children}</div>
    )
}

export default GridCell