import Grid from "../../components/Grid"
import GridCell from "../../components/GridCell"
import IllustrationCard from "../../components/IllustrationCard"
import PhotoCard from "../../components/PhotoCard"
import SectionContainer from "../../components/SectionContainer"
import SectionTitle from "../../components/SectionTitle"
import Spacer from "../../components/Spacer"

function ServicesSection({ title, services, cardType = "illustration" }) {
    return (
        <SectionContainer>
            <Spacer height={80} />
            <SectionTitle title={title} />
            <Spacer height={80} />
            <Grid gutter={40} maxColumns={3}>
                {services.map((s, i) => (
                    <GridCell key={i}>
                        {
                            (() => {
                                switch (cardType) {
                                    case "illustration":
                                        return <IllustrationCard
                                            theme='blue'
                                            illustrationURL={s.imgURL}
                                            title={s.title}
                                            paragraph={s.paragraph}
                                        />
                                    case "photo":
                                        return <PhotoCard
                                            theme='blue'
                                            photoURL={s.imgURL}
                                            title={s.title}
                                            paragraph={s.paragraph}
                                        />

                                    default:
                                        break;
                                }
                            })()
                        }
                        {/* <IllustrationCard
                            theme='blue'
                            illustrationURL={s.illustrationURL}
                            title={s.title}
                            paragraph={s.paragraph}
                        /> */}
                    </GridCell>
                ))}
            </Grid>
            <Spacer height={140} />
        </SectionContainer>
    )
}

export default ServicesSection