import CheckItem from "../../components/CheckItem"
import Grid from "../../components/Grid"
import GridCell from "../../components/GridCell"
import SectionContainer from "../../components/SectionContainer"
import Spacer from "../../components/Spacer"

function AdvantagesSection({theme}) {
    return (
        <SectionContainer theme={theme}>
            <Spacer height={30} />
            <div style={{ padding: "0 20px" }}>
                <Grid gutter={40} maxColumns={3}>
                    <GridCell style={{ display: "flex", justifyContent: "center" }}>
                        <CheckItem title="Traitement Rapide" />
                    </GridCell>
                    <GridCell style={{ display: "flex", justifyContent: "center" }}>
                        <CheckItem title="Expertise Professionnelle" />
                    </GridCell>
                    <GridCell style={{ display: "flex", justifyContent: "center" }}>
                        <CheckItem title="Aucun Coût Caché" />
                    </GridCell>
                </Grid>
                <Spacer height={30} />
            </div>
        </SectionContainer>
    )
}

export default AdvantagesSection