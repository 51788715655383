import Button from "../../components/Button";
import SectionContainer from "../../components/SectionContainer";
import Spacer from "../../components/Spacer";

function HeroSection({ imageURL, title, paragraph, actionTitle, actionURL, theme }) {
    return (
        <div className="hero-section">
            <Spacer height={50} />
            <div className="hero-image">
                <img src={imageURL} alt="épave" />
            </div>
            <div className="hero-overlay"></div>
            <SectionContainer color="transparent">
                <Spacer height={100} />
                <div style={{ maxWidth: "400px", position: "relative" }}>
                    <h1 className="text-style-big-header" style={{ color: "var(--white)" }}>
                        {title.split("\n").map((t, i) => <div key={i}>{t}</div>)}
                    </h1>
                    <Spacer height={40} />
                    <p className="text-style-paragraph" style={{ color: "var(--white-opacity-70)" }} >
                        {paragraph}
                    </p>
                    <Spacer height={60} />
                    <a href={actionURL} style={{ display: "block", width: "fit-content" }}>
                        <Button title={actionTitle} theme={theme} size="small" />
                    </a>
                    <Spacer height={140} />
                </div>
            </SectionContainer>
        </div>
    )
}

export default HeroSection