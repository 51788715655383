import "./CheckItem.scss";

function CheckItem({ title, theme = "blue" }) {
    const classList = ["ev-comp", "ev-comp-check-item", `theme-${theme}`];
    return (
        <div className={classList.join(" ")}>
            <div className="check">
                <img src="assets/icons/icon-check-blue.svg" alt="check icon" />
            </div>
            <div className="title text-style-check-item">{title}</div>
        </div>
    )
}

export default CheckItem