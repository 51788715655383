import FaqCard from "../components/FaqCard.jsx"
import Grid from "../components/Grid.jsx"
import GridCell from "../components/GridCell.jsx"
import IconCard from "../components/IconCard.jsx"
import SectionContainer from "../components/SectionContainer.jsx"
import SectionTitle from "../components/SectionTitle.jsx"
import Spacer from "../components/Spacer.jsx"
import Button from "../components/Button.jsx"
import StepCard from "../components/StepCard.jsx"
import HeroSection from "./sections/HeroSection.jsx"
import AdvantagesSection from "./sections/AdvantagesSection.jsx"
import ServicesSection from "./sections/ServicesSection.jsx"
import FormSection from "./sections/FormSection.jsx"

function LandingPage({ theme }) {

    return (
        <>
            <HeroSection
                imageURL="assets/photos/hero-photo-6.jpg"
                title={`EPAVITE,\nC'EST RAPIDE`}
                paragraph={"Epavite, votre partenaire de confiance à Bordeaux et dans toute la région d'Aquitaine. Libérez l'espace avec notre service d'enlèvement d'épaves gratuit, roulez en toute tranquillité avec notre remorquage rapide, et restez mobile avec notre dépannage expert pour voitures et deux-roues. Trois services, une mission : assurer votre sécurité et votre mobilité sur la route. Explorez nos solutions dès maintenant!"}
                actionURL={"#section-form"}
                actionTitle={"enlever votre épave gratuitement"}
                theme={theme}
            />
            <AdvantagesSection theme="blue" />
            <ServicesSection
                cardType="illustration"
                title={"Epave, en pane ou nouvelle, on le traite vite"}
                services={[
                    {
                        imgURL: "assets/illustrations/car-crashed.svg",
                        title: "Se débarasser d'une épave",
                        paragraph: "Éliminez votre épave encombrante avec facilité. Notre service d'enlèvement d'épaves gratuit vous offre une solution écologique et sans tracas. Libérez de l'espace et contribuez à un environnement plus propre."
                    },
                    {
                        imgURL: "assets/illustrations/car-safe.svg",
                        title: "Dépanner un véhicule en panne",
                        paragraph: "Soyez tranquille sur la route. Notre service de dépannage expert intervient rapidement pour remettre votre véhicule en état de marche. Que vous soyez en panne sur deux ou quatre roues, nous sommes là pour vous sauver."
                    },
                    {
                        imgURL: "assets/illustrations/car-carried.svg",
                        title: "Remorquer une voiture",
                        paragraph: "Besoin d'un déplacement sans stress? Optez pour notre service de remorquage rapide. Peu importe la taille ou le type de votre véhicule, nous le remorquons en toute sécurité. Choisissez l'efficacité avec Epavite."
                    }
                ]}
            />
            <SectionContainer theme={theme}>
                <Spacer height={80} />
                <SectionTitle title="Service simple et vite en 2 Étapes" />
                <Spacer height={80} />
                <div className="steps-section-box">
                    <StepCard number="1" title="Appelez" theme={theme} />
                    <div className="lines">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                    <StepCard number="2" title="C'est fait" theme={theme} />
                </div>
                <Spacer height={80} />
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <a href="tel: +33536661000">
                        <Button title="Appelez nous" theme={theme} />
                    </a>
                </div>
                <Spacer height={140} />
            </SectionContainer>
            <SectionContainer>
                <Spacer height={80} />
                <SectionTitle title="Épavite Transforme vos Soucis en Bénéfices" />
                <Spacer height={80} />
                <Grid gutter={40} maxColumns={3}>
                    <GridCell>
                        <IconCard
                            theme={theme}
                            iconURL="assets/icons/icon-time-orange.svg"
                            title="Rapidité"
                            paragraph="Epavite, votre solution instantanée sur la route. Que ce soit pour l'enlèvement d'épaves, le dépannage ou le remorquage, notre réactivité assure une assistance rapide et efficace, à tout moment."
                        />
                    </GridCell>
                    <GridCell>
                        <IconCard
                            theme={theme}
                            iconURL="assets/icons/icon-euro-orange.svg"
                            title="Economie"
                            paragraph="Choisir Epavite, c'est choisir l'efficacité sans compromis sur votre budget. Profitez de l'enlèvement gratuit des épaves, de tarifs compétitifs, et économisez tout en bénéficiant de services de qualité."
                        />
                    </GridCell>
                    <GridCell>
                        <IconCard
                            theme={theme}
                            iconURL="assets/icons/icon-smile-orange.svg"
                            title="Simplicité"
                            paragraph="Simplicité, du début à la fin. Avec Epavite, éliminez les épaves, sauvez un véhicule en panne, ou remorquez une voiture en toute simplicité. Des solutions claires pour une expérience sans tracas."
                        />
                    </GridCell>
                </Grid>
                <Spacer height={140} />
            </SectionContainer>

            <SectionContainer theme="blue">
                <Spacer height={80} />
                <SectionTitle title="Besoin d'infos? Explorez Nos Questions Fréquemment Posées!" />
                <Spacer height={80} />
                <div style={{ maxWidth: "var(--section-inner-width)", margin: "0 auto" }}>
                    <FaqCard
                        theme="blue"
                        question="Pour qui est destiné le service d'un épaviste agréé?"
                        answer="La seule exigence pour envoyer votre vieille voiture à la casse est d'être son propriétaire. À ce titre, vous pourrez établir les documents de cession en collaborant avec un épaviste agréé, qui prendra en charge l'enlèvement de votre épave en vue de sa destruction."
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Pour quels types de véhicules?"
                        answer="Les véhicules éligibles pour notre service comprennent tous les véhicules à moteur dotés de quatre roues, dont le poids ne dépasse pas 3,5 tonnes. La plupart de ces véhicules sont des voitures particulières qui ne sont plus en état de rouler, soit en raison de leur dangerosité, de leur ancienneté, de leur irréparabilité sur le plan économique, ou du fait qu'ils ne sont plus assurés. Il est important de noter que, pour le moment, notre service ne concerne pas les deux-roues, les caravanes et les véhicules dépassant les 3,5 tonnes."
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Les véhicules immatriculés à l'étranger sont-ils concernés?"
                        answer="Pour déterminer si nous pouvons récupérer votre véhicule immatriculé à l'étranger, certaines conditions spécifiques s'appliquent. Nous vous invitons à prendre contact avec notre service téléphonique pour obtenir des informations détaillées à ce sujet."
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Epavite est-il totalement gratuit?"
                        answer={`Certaines conditions spécifiques doivent être remplies, nous vous recommandons de contacter notre service téléphonique pour vérifier la faisabilité de la récupération.

                        Quant à la gratuité d'Epavite, ce service permet de se débarrasser de son véhicule hors d'usage (VHU) de manière simple, économique et écologique, tout en respectant la réglementation environnementale et administrative.
                        
                        Les objectifs d'Epavite sont les suivants :
                        
                        - Faciliter et sécuriser vos démarches pour vous débarrasser de votre vieux véhicule.
                        - Proposer une solution d'enlèvement par un épaviste agréé.
                        - Garantir le dépolluage et le recyclage de votre véhicule.
                        - Fournir le certificat de destruction CERFA N°14365*01, nécessaire pour dégager votre responsabilité, arrêter votre assurance et bénéficier de la prime à la casse.
                        - Approvisionner les centres VHU agréés en véhicules à recycler.
                        
                        Lors de votre demande d'enlèvement chez EPAVITE, nous vous assurons :
                        
                        - L'enlèvement rapide de votre véhicule par un centre VHU agréé.
                        - Un délai d'attente minimal pour récupérer votre épave.
                        - Le remboursement des frais de déplacement pour tout enlèvement non effectué.`}
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Quels sont les papiers à fournir pour vendre mon véhicule?"
                        answer={`1- La Carte Grise du véhicule ou déclaration de perte/vol
                        2 - Une copie de votre pièce d'identité 
                        3 - Une attestation de non gage
                        4 - Les clefs du véhicule 
                        5 - Le deuxième volet de la cession du véhicule que nous apporterons"`}
                    />
                </div>
                <Spacer height={140} />
            </SectionContainer>
            <FormSection theme={theme}
                hsForm={{
                    portalId: "143797396",
                    formId: "b9ac2092-b99f-49dd-9196-044ad9bd8b3c"
                }}
                formData={
                    [
                        { key: "firstname", title: "Prénom", type: "text", icon: "person" },
                        { key: "lastname", title: "Nom", type: "text", icon: "person" },
                        { key: "phone", title: "Numéro de téléphone", type: "text", icon: "phone" },
                        { key: "email", title: "Adresse mail", type: "text", icon: "mail" },
                        {
                            key: "service", title: "choisir un service", type: "select", icon: "car", options: [
                                { title: "Enlèvement d'épave", value: "en-epave" },
                                { title: "Dépannage Batterie", value: "dep-batterie" },
                                { title: "Dépannage Crevaison", value: "dep-crevaison" },
                                { title: "Dépannage Carburant", value: "dep-carburant" },
                                { title: "Remorquage Véhicule", value: "rem-vehicule" },
                                { title: "Remorquage 2 Roues", value: "rem-2-roues" },
                            ]
                        }
                    ]
                }>
                <h2 className="text-style-header" style={{ color: "var(--blue-dark)" }}>
                    Pour une assistance immédiate, n'hésitez pas à remplir le formulaire en ligne ou à nous appeler directement.
                </h2>
                <Spacer height={80} />
                <a href="tel: +33536661000" style={{ display: "block", width: "fit-content" }}>
                    <Button title="Appelez nous" theme={theme} />
                </a>
                <Spacer height={140} />
            </FormSection>
            {/* <SectionContainer>
                <Spacer height={80} />
                <Grid gutter={40} maxColumns={3}>
                    <GridCell>
                        <ReviewCard
                            theme='orange'
                            photoURL="assets/photos/bob-ross.jpg"
                            name="Bob Ross"
                            paragraph="Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, voluptates! Ratione, minus quas hic fugit odio itaque numquam accusamus, recusandae similique possimus obcaecati ipsum."
                        />
                    </GridCell>
                    <GridCell>
                        <ReviewCard
                            theme='orange'
                            photoURL="assets/photos/bob-ross.jpg"
                            name="Bob Ross"
                            paragraph="Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, voluptates! Ratione, minus quas hic fugit odio itaque numquam accusamus, recusandae similique possimus obcaecati ipsum."
                        />
                    </GridCell>
                    <GridCell>
                        <ReviewCard
                            theme='orange'
                            photoURL="assets/photos/bob-ross.jpg"
                            name="Bob Ross"
                            paragraph="Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, voluptates! Ratione, minus quas hic fugit odio itaque numquam accusamus, recusandae similique possimus obcaecati ipsum."
                        />
                    </GridCell>
                </Grid>
                <Spacer height={140} />
            </SectionContainer> */}
        </>
    )
}

export default LandingPage