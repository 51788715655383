import FaqCard from "../components/FaqCard.jsx"
import Grid from "../components/Grid.jsx"
import GridCell from "../components/GridCell.jsx"
import IconCard from "../components/IconCard.jsx"
import SectionContainer from "../components/SectionContainer.jsx"
import SectionTitle from "../components/SectionTitle.jsx"
import Spacer from "../components/Spacer.jsx"
import Button from "../components/Button.jsx"
import StepCard from "../components/StepCard.jsx"
import HeroSection from "./sections/HeroSection.jsx"
import AdvantagesSection from "./sections/AdvantagesSection.jsx"
import ServicesSection from "./sections/ServicesSection.jsx"
import FormSection from "./sections/FormSection.jsx"

function LandingPage({ theme }) {

    return (
        <>
            <HeroSection
                imageURL="assets/photos/hero-photo-8.jpg"
                title={` Épaviste Pro pour un Espace Sans Encombre!`}
                paragraph={"Découvrez notre service d'épaviste rapide, la solution instantanée pour libérer votre espace en un clin d'œil. Économisez intelligemment sur l'enlèvement d'épaves tout en simplifiant votre vie automobile. Optez pour la rapidité, l'économie et la simplicité avec notre service d'enlèvement d'épaves."}
                actionURL={"#section-form"}
                actionTitle={"ENLÈVEMENT D'ÉPAVES GRATUIT"}
                theme={theme}
            />
            <AdvantagesSection theme="blue" />
            <ServicesSection
                cardType="photo"
                title={"Valorisez l'Espace, Libérez les Possibilités"}
                services={[
                    {
                        imgURL: "assets/photos/epa-service-unused.jpg",
                        title: "Véhicules hors d'usage",
                        paragraph: "Libérez votre jardin et transformez votre espace extérieur en une oasis de tranquillité. Avec notre service d'enlèvement d'épaves, débarrassez-vous efficacement de tout véhicule hors d'usage qui encombre votre environnement, redonnant ainsi vie à votre jardin."
                    },
                    {
                        imgURL: "assets/photos/epa-service-accident.jpg",
                        title: "Véhicules accidentés",
                        paragraph: "Votre véhicule a été marqué par un accident et ne peut être réparé selon l'assurance ? Epavite vient à la rescousse. Confiez-nous votre véhicule accidenté, et libérez-vous des contraintes visuelles et émotionnelles associées."
                    },
                    {
                        imgURL: "assets/photos/epa-service-fire.jpg",
                        title: "Véhicules brulés",
                        paragraph: "Les dégâts d'incendie peuvent être déconcertants, mais ne laissez pas une épave brûlée vous retenir. Optez pour notre service d'enlèvement d'épaves, transformant les cicatrices extérieures en opportunités de renouveau."
                    },
                    {
                        imgURL: "assets/photos/epa-service-water.jpg",
                        title: "Véhicules submergés",
                        paragraph: "Epavite n'a pas peur des situations extrêmes. Que votre véhicule ait été submergé dans une inondation ou un autre incident, notre équipe intervient avec rapidité et efficacité pour vous débarrasser de cette charge aquatique."
                    },
                    {
                        imgURL: "assets/photos/epa-service-road.jpg",
                        title: "Véhicules sur la voie publique",
                        paragraph: "Vous avez repéré un véhicule abandonné gâchant l'esthétique de la voie publique ? Epavite peut résoudre ce problème pour vous. Notre équipe réagit rapidement pour libérer l'espace public de tout véhicule non désiré."
                    },
                    {
                        imgURL: "assets/photos/epa-service-moto.jpg",
                        title: "2 roues, utilitaires, camions",
                        paragraph: "Propriétaire d'un autre type de véhicule ? Aucun problème pour Epavite. Que ce soit un deux-roues, un utilitaire ou un camion, notre service d'enlèvement d'épaves s'adapte à vos besoins, vous libérant de tout encombrement."
                    }
                ]}
            />
            <SectionContainer theme={theme}>
                <Spacer height={80} />
                <SectionTitle title="Service simple et vite en 2 Étapes" />
                <Spacer height={80} />
                <div className="steps-section-box">
                    <StepCard number="1" title="Appelez" theme={theme} />
                    <div className="lines">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                    <StepCard number="2" title="C'est fait" theme={theme} />
                </div>
                <Spacer height={80} />
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <a href="tel: +33536661000">
                        <Button title="Appelez nous" theme={theme} />
                    </a>
                </div>
                <Spacer height={140} />
            </SectionContainer>
            <SectionContainer>
                <Spacer height={80} />
                <SectionTitle title="Épavite Transforme vos Soucis en Bénéfices" />
                <Spacer height={80} />
                <Grid gutter={40} maxColumns={3}>
                    <GridCell>
                        <IconCard
                            theme={theme}
                            iconURL={`assets/icons/icon-time-${theme}.svg`}
                            title="Libérez Instantanément l'Espace!"
                            paragraph="Epavite vous offre une solution rapide sur la route pour l'enlèvement d'épaves. Que ce soit pour retirer une épave encombrante ou pour toute autre nécessité d'enlèvement, notre réactivité assure une intervention rapide et efficace, à tout moment."
                        />
                    </GridCell>
                    <GridCell>
                        <IconCard
                            theme={theme}
                            iconURL={`assets/icons/icon-euro-${theme}.svg`}
                            title="Économisez Intelligemment sur l'Enlèvement"
                            paragraph="Choisir Epavite signifie opter pour l'efficacité sans compromis sur votre budget. Bénéficiez de l'enlèvement gratuit des épaves, avec des services de qualité exceptionnelle tout en économisant."
                        />
                    </GridCell>
                    <GridCell>
                        <IconCard
                            theme={theme}
                            iconURL={`assets/icons/icon-smile-${theme}.svg`}
                            title="Simplifiez l'Enlèvement d'Épaves"
                            paragraph="Simplicité, du début à la fin. Avec Epavite, éliminez les épaves en toute simplicité. Des solutions claires pour vous débarrasser d'une épave de manière rapide et efficace, offrant une expérience sans tracas de bout en bout."
                        />
                    </GridCell>
                </Grid>
                <Spacer height={140} />
            </SectionContainer>

            <SectionContainer theme="blue">
                <Spacer height={80} />
                <SectionTitle title="Des Questions sur l'Enlèvement d'Épaves ? Explorez Nos Réponses !" />
                <Spacer height={80} />
                <div style={{ maxWidth: "var(--section-inner-width)", margin: "0 auto" }}>
                    <FaqCard
                        theme="blue"
                        question="Quels types de véhicules acceptez-vous pour l'enlèvement d'épaves?"
                        answer="Nous acceptons tous types de véhicules, des voitures aux camions, pour un enlèvement rapide et efficace. Qu'il s'agisse de véhicules non roulants, accidentés, ou simplement hors d'usage, nous sommes là pour simplifier le processus."
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Est-ce que l'enlèvement d'épaves est gratuit?"
                        answer="Oui, notre service d'enlèvement d'épaves est entièrement gratuit, sans frais cachés. Libérez-vous de l'encombrement sans vous soucier des coûts."
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Quelle est la zone de couverture de vos services?"
                        answer="Notre intervention se concentre principalement dans la région d'Aquitaine, assurant une réponse rapide à vos besoins. Nous couvrons l'ensemble de la région pour une assistance facile et accessible."
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Comment puis-je planifier l'enlèvement de mon épave?"
                        answer="La planification est facile. Remplissez simplement notre formulaire en ligne ou appelez-nous directement pour organiser l'enlèvement de votre épave. Une solution sans tracas, adaptée à votre emploi du temps."
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="En combien de temps intervenez-vous après ma demande?"
                        answer="Notre équipe s'efforce d'intervenir dans les plus brefs délais, généralement dans les 24 heures suivant votre demande. Profitez d'une assistance rapide et efficace pour vous libérer de votre épave rapidement."
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Que se passe-t-il avec mon véhicule après l'enlèvement?"
                        answer="Votre épave est presque intégralement recyclée, contribuant à des pratiques respectueuses de l'environnement. Les pièces détachées réutilisables sont mises à la disposition de consommateurs cherchant des alternatives économiques pour la réparation de leur propre véhicule. C'est ainsi que notre service demeure gratuit, tout en valorisant les matériaux de manière responsable."
                    />
                </div>
                <Spacer height={140} />
            </SectionContainer >
            <FormSection theme={theme}
                hsForm={{
                    portalId: "143797396",
                    formId: "b9ac2092-b99f-49dd-9196-044ad9bd8b3c"
                }}
                formData={
                    [
                        { key: "firstname", title: "Prénom", type: "text", icon: "person" },
                        { key: "lastname", title: "Nom", type: "text", icon: "person" },
                        { key: "phone", title: "Numéro de téléphone", type: "text", icon: "phone" },
                        { key: "email", title: "Adresse mail", type: "text", icon: "mail" },
                        {
                            key: "vehicule", title: "Type de véhicule", type: "select", icon: "car", options: [
                                { title: "Voiture", value: "voiture" },
                                { title: "2 Roues", value: "2-roues" },
                                { title: "Camionette", value: "camionette" },
                                { title: "Autre type de véhicule", value: "autre" },
                            ]
                        },
                        {
                            key: "service", title: "Type d'épave", type: "select", icon: "car", options: [
                                { title: "Épave hors usage", value: "epa-not-used" },
                                { title: "Épave accidenté", value: "epa-accident" },
                                { title: "Épave brûlé", value: "epa-brule" },
                                { title: "Épave submergé", value: "epa-submerge" },
                                { title: "Épave abandonné", value: "epa-abandonne" },
                                { title: "Autre type d'épave", value: "epa-autre" },
                            ]
                        }
                    ]
                }>
                <h2 className="text-style-header" style={{ color: "var(--blue-dark)" }}>
                Demandez notre service d'enlèvement d'épaves : appelez-nous ou remplissez le formulaire ci-après!
                </h2>
                <Spacer height={80} />
                <a href="tel: +33536661000" style={{ display: "block", width: "fit-content" }}>
                    <Button title="Appelez nous" theme={theme} />
                </a>
                <Spacer height={140} />
            </FormSection>
            {/* <SectionContainer>
                <Spacer height={80} />
                <Grid gutter={40} maxColumns={3}>
                    <GridCell>
                        <ReviewCard
                            theme='orange'
                            photoURL="assets/photos/bob-ross.jpg"
                            name="Bob Ross"
                            paragraph="Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, voluptates! Ratione, minus quas hic fugit odio itaque numquam accusamus, recusandae similique possimus obcaecati ipsum."
                        />
                    </GridCell>
                    <GridCell>
                        <ReviewCard
                            theme='orange'
                            photoURL="assets/photos/bob-ross.jpg"
                            name="Bob Ross"
                            paragraph="Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, voluptates! Ratione, minus quas hic fugit odio itaque numquam accusamus, recusandae similique possimus obcaecati ipsum."
                        />
                    </GridCell>
                    <GridCell>
                        <ReviewCard
                            theme='orange'
                            photoURL="assets/photos/bob-ross.jpg"
                            name="Bob Ross"
                            paragraph="Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, voluptates! Ratione, minus quas hic fugit odio itaque numquam accusamus, recusandae similique possimus obcaecati ipsum."
                        />
                    </GridCell>
                </Grid>
                <Spacer height={140} />
            </SectionContainer> */}
        </>
    )
}

export default LandingPage