
function Colors({ theme = "blue" }) {
    return (
        <div style={{ display: "flex", gap: "5px", padding: "20px" }}>
            {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 95, 98, 99, 100].map((c) => (
                <div>
                    <div style={{ fontFamily: '"BAHNSCHRIFT"', fontSize: "0.8em" }}>{c}</div>
                    <div style={{ height: "50px", width: "50px", border: "1px solid black", backgroundColor: `var(--${theme}-tone-${c})` }}>
                    </div>
                </div>
            ))}
        </div >
    )
}

export default Colors