
const DIRECTOR = "JOHN DOE"
const HOSTING_COMPANY = "SOLUSAAS SARLs"
const FULL_ADDRESS = "address";
const PHONE_NUMBER = "12345678";
const WEBSITE = "https://epavite.fr"
const EMAIL_ADDRESS = "contact@epavite.fr";
const SIRET = "000000";
const RCS = "1111111";
const TVA = "2222222";

const posts = [
    {
        id: "1",
        content: `# Mentions Légales

## Éditeur du site

Epavite  
${FULL_ADDRESS}  
${PHONE_NUMBER}  
${EMAIL_ADDRESS}

**SIRET**: ${SIRET}  
**RCS**: ${RCS}  
**TVA intracommunautaire**: ${TVA}

## Directeur de la publication

${DIRECTOR}  
[Contact Information of the Director]

## Hébergeur du site

${HOSTING_COMPANY}  
${FULL_ADDRESS}  
${PHONE_NUMBER}  
${EMAIL_ADDRESS}

## Propriété intellectuelle

Ce site et son contenu sont la propriété exclusive d'Epavite. Toute reproduction, même partielle, est strictement interdite sans autorisation préalable.

## Protection des données personnelles

Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 modifiée et au Règlement Général sur la Protection des Données (RGPD), vous disposez d'un droit d'accès, de rectification, de suppression et d'opposition aux données vous concernant. Pour exercer ces droits, veuillez nous contacter à **${EMAIL_ADDRESS}**.

## Cookies

Ce site utilise des cookies pour améliorer votre expérience. En continuant à naviguer sur ce site, vous acceptez l'utilisation de cookies.

## Liens externes

Epavite n'est pas responsable du contenu des sites externes liés à [epavite.fr](${WEBSITE}).

## Contact

Pour toute question ou réclamation, veuillez nous contacter à **${EMAIL_ADDRESS}** ou par courrier à l'adresse mentionnée ci-dessus.`
    },
    {
        id: "2",
        content: `# Politique de Confidentialité

La présente Politique de Confidentialité régit la manière dont Epavite collecte, utilise, conserve et divulgue les informations collectées auprès des utilisateurs (ci-après dénommés "Utilisateurs") du site [epavite.fr](https://epavite.fr).

## Collecte d'informations personnelles

Nous pouvons collecter des informations personnelles auprès des Utilisateurs de différentes manières, notamment lorsque les Utilisateurs visitent notre site, passent une commande, remplissent un formulaire ou interagissent avec d'autres fonctionnalités disponibles sur notre site.

Les informations personnelles que nous pouvons collecter comprennent le nom, l'adresse e-mail, l'adresse postale, le numéro de téléphone, et d'autres informations nécessaires aux fins spécifiées lors de la collecte de ces informations.

## Utilisation des informations collectées

Epavite collecte et utilise les informations personnelles des Utilisateurs aux fins suivantes :

- Pour améliorer notre site : Nous nous efforçons constamment d'améliorer nos offres de produits et nos services en fonction des informations et des retours que nous recevons de nos Utilisateurs.
- Pour traiter les transactions : Nous pouvons utiliser les informations que les Utilisateurs fournissent lors de la commande pour traiter celle-ci. Nous ne partageons pas ces informations avec des tiers, sauf dans la mesure nécessaire pour fournir le service.
- Pour envoyer des e-mails périodiques : Nous pouvons utiliser l'adresse e-mail pour envoyer des informations et mises à jour relatives à la commande. Elle peut également être utilisée pour répondre aux questions des Utilisateurs et/ou d'autres demandes.

## Protection des informations

Nous mettons en œuvre des pratiques appropriées de collecte, de stockage et de traitement des données, ainsi que des mesures de sécurité afin de protéger contre tout accès non autorisé, altération, divulgation ou destruction de vos informations personnelles, nom d'utilisateur, mot de passe, informations de transaction et données stockées sur notre site.

## Partage de vos informations personnelles

Nous ne vendons, n'échangeons ni ne louons les informations personnelles des Utilisateurs à des tiers. Nous pouvons partager des informations agrégées et non personnellement identifiables avec des tiers pour des fins de marketing, de publicité ou d'autres utilisations.

## Modifications de cette politique de confidentialité

Epavite se réserve le droit de mettre à jour cette politique de confidentialité à tout moment. Nous encourageons les Utilisateurs à consulter fréquemment cette page pour rester informés sur la manière dont nous protégeons les informations personnelles que nous collectons. Vous reconnaissez et acceptez qu'il est de votre responsabilité de vérifier périodiquement cette politique de confidentialité et de vous familiariser avec les modifications.

## Contactez-nous

Si vous avez des questions concernant cette politique de confidentialité, les pratiques de ce site ou vos relations avec ce site, veuillez nous contacter à [votre@email.com].

Dernière mise à jour : [Date de la dernière mise à jour]`
    },
    {
        id: "3",
        content: `# Conditions d'Utilisation

Bienvenue sur le site web d'Epavite, accessible à l'adresse [epavite.fr](https://epavite.fr) (ci-après dénommé "le Site"). En accédant et en utilisant ce Site, vous acceptez les présentes Conditions d'Utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser le Site.

## Utilisation du Site

En accédant et en utilisant le Site, vous vous engagez à respecter toutes les lois et réglementations applicables et à être responsable du respect des présentes Conditions d'Utilisation. Vous acceptez de ne pas utiliser le Site à des fins illégales ou interdites par ces conditions.

## Propriété intellectuelle

Le contenu du Site, y compris, mais sans s'y limiter, le texte, les graphiques, les logos, les images, le code et le logiciel, est la propriété exclusive d'Epavite et est protégé par les lois françaises et internationales sur les droits d'auteur.

## Liens vers d'autres sites web

Le Site peut contenir des liens vers des sites web tiers. Ces liens sont fournis uniquement pour votre commodité. Epavite n'a aucun contrôle sur le contenu de ces sites web tiers et décline toute responsabilité quant à leur contenu.

## Modification des Conditions d'Utilisation

Epavite se réserve le droit de modifier ces Conditions d'Utilisation à tout moment. Il est de votre responsabilité de vérifier régulièrement les modifications. En continuant à utiliser le Site après la publication de modifications, vous acceptez lesdites modifications.

## Limitation de responsabilité

En aucun cas, Epavite ne sera responsable de tout dommage direct, indirect, spécial, consécutif ou accessoire résultant de l'utilisation ou de l'impossibilité d'utiliser le Site, même si Epavite a été informé de la possibilité de tels dommages.

## Contactez-nous

Si vous avez des questions concernant ces Conditions d'Utilisation, veuillez nous contacter à [votre@email.com].

Dernière mise à jour : [Date de la dernière mise à jour]`
    }
]

export default posts