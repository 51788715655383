
import "./NavList.scss";

function NavList({ linksData }) {
    const classList = ["ev-comp", "ev-comp-nav-list"]
    return (
        <div className={classList.join(" ")}>
            {
                linksData.map((link, index) => (
                    <a key={index} className="link text-style-nav-list-link" href={link.href}>
                        {link.title}
                    </a>
                ))
            }
        </div>
    )
}

export default NavList