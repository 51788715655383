import "./StepCard.scss";

function StepCard({ number, title, theme = "orange" }) {
    const classList = ["ev-comp", "ev-comp-step-card", `theme-${theme}`]
    return (
        <div className={classList.join(" ")}>
            <div className="number text-style-card-title">{number}</div>
            <div className="title text-style-card-title">{title}</div>
        </div>
    )
}

export default StepCard