import SectionContainer from "../../components/SectionContainer"
import Spacer from "../../components/Spacer"

function Footer() {
    return (
        <footer className="footer">
            <SectionContainer color="transparent">
                <Spacer height={60} />
                <div className="footer-links">
                    <a href="/mentions-legales" className="text-style-paragraph">Mentions legales</a>
                    <a href="/cgu" className="text-style-paragraph">Conditions d'utilisation</a>
                    <a href="/politique-de-confidentialite" className="text-style-paragraph">Politique de Confidentialité</a>
                </div>
                <Spacer height={60} />
                <div style={{ height: "1px", backgroundColor: "var(--white)", opacity: "0.5" }}></div>
                <Spacer height={20} />
                <span className="copyright text-style-label" style={{ color: "var(--white)" }}>copyright &copy; 2023</span>
                <Spacer height={20} />
            </SectionContainer>
        </footer>
    )
}

export default Footer