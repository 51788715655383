import "./FaqCard.scss";
import { useState, useRef, useEffect } from 'react';

function FaqCard({ theme = "orange", question, answer, isinitiallyOpen = false }) {
    const [isOpen, setIsOpen] = useState(isinitiallyOpen);
    const [answerMaxHeight, setAnswerMaxHeight] = useState(0);
    const classList = ["ev-comp", "ev-comp-faq-card", `theme-${theme}`];
    const answerRef = useRef();

    useEffect(() => {
        isOpen ? setAnswerMaxHeight(answerRef.current.scrollHeight) : setAnswerMaxHeight(0);
    }, [isOpen])

    window.addEventListener("resize", () => {
        isOpen ? setAnswerMaxHeight(answerRef.current.scrollHeight) : setAnswerMaxHeight(0);
    })

    return (
        <div className={classList.join(" ")}>
            <div className="question" onClick={() => { setIsOpen((prev) => !prev) }}>
                <div className="text text-style-card-faq-question">{question}</div>
                <div className="plus">
                    <img width={30} height={30} src={`./assets/icons/icon-${isOpen ? "minus" : "plus"}-white.svg`} alt="question button" />
                </div>
            </div>
            <div ref={answerRef} style={{ "--answer-max-height": `${answerMaxHeight}px` }} className="answer text-style-card-paragraph">
                <div className="container">
                    {answer.split("\n").map(((p, i) => {
                        const whiteSpacePattern = /^\s+$/;
                        if (whiteSpacePattern.test(p)) {
                            return <br key={i} />;
                        } else {
                            return <p key={i}>{p}</p>;
                        }
                    }))}
                </div>
            </div>
        </div>
    )
}

export default FaqCard