import FaqCard from "../components/FaqCard.jsx"
import Grid from "../components/Grid.jsx"
import GridCell from "../components/GridCell.jsx"
import IconCard from "../components/IconCard.jsx"
import SectionContainer from "../components/SectionContainer.jsx"
import SectionTitle from "../components/SectionTitle.jsx"
import Spacer from "../components/Spacer.jsx"
import Button from "../components/Button.jsx"
import StepCard from "../components/StepCard.jsx"
import HeroSection from "./sections/HeroSection.jsx"
import AdvantagesSection from "./sections/AdvantagesSection.jsx"
import ServicesSection from "./sections/ServicesSection.jsx"
import FormSection from "./sections/FormSection.jsx"

function LandingPage({ theme }) {

    return (
        <>
            <HeroSection
                imageURL="assets/photos/hero-photo-7.jpg"
                title={`Service de Dépannage Routier 24/7 avec Epavite.`}
                paragraph={"Bienvenue chez Epavite, votre partenaire réactif pour l'assistance routière personnalisée. Que ce soit pour une crevaison, un besoin urgent de carburant ou des problèmes de batterie, nous sommes là pour intervenir rapidement 24/7. Avec Epavite, reprenez la route en toute confiance."}
                actionURL={"#section-form"}
                actionTitle={"DEPANNAGE/REMORQUAGE GRATUIT"}
                theme={theme}
            />
            <AdvantagesSection theme="blue" />
            <ServicesSection
                cardType="photo"
                title={"Assistance Routière Personnalisée pour Votre Sécurité"}
                services={[
                    {
                        imgURL: "assets/photos/dep-photo-1.jpg",
                        title: "Assistance Rapide pour les Crevaisons",
                        paragraph: "En cas de crevaison, nous sommes là pour vous aider rapidement. Notre équipe expérimentée se rendra sur place, réparera ou remplacera votre pneu et vous remettra sur la route en un rien de temps. Nous comprenons l'importance de votre temps et travaillons efficacement pour vous assurer une expérience sans tracas."
                    },
                    {
                        imgURL: "assets/photos/dep-photo-2.jpg",
                        title: "Livraison de Carburant sur Place",
                        paragraph: "Besoin d'un appoint en carburant en plein milieu de nulle part? Ne vous inquiétez pas! Nous offrons un service de livraison de carburant directement sur votre lieu d'arrêt. Que vous soyez à court d'essence ou de diesel, nous sommes là avec une solution rapide et fiable pour vous remettre en route."
                    },
                    {
                        imgURL: "assets/photos/dep-photo-3.jpg",
                        title: "Dépannage de Batterie 24/7",
                        paragraph: "Les pannes de batterie peuvent survenir à tout moment. Notre équipe est prête à intervenir 24 heures sur 24, 7 jours sur 7, pour vous fournir une assistance rapide. Que ce soit pour un démarrage de batterie, une recharge ou un remplacement, nous sommes équipés pour résoudre vos problèmes de batterie sur place."
                    }
                ]}
            />
            <SectionContainer theme={theme}>
                <Spacer height={80} />
                <SectionTitle title="Service simple et vite en 2 Étapes" />
                <Spacer height={80} />
                <div className="steps-section-box">
                    <StepCard number="1" title="Appelez" theme={theme} />
                    <div className="lines">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                    <StepCard number="2" title="C'est fait" theme={theme} />
                </div>
                <Spacer height={80} />
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <a href="tel: +33536661000">
                        <Button title="Appelez nous" theme={theme} />
                    </a>
                </div>
                <Spacer height={140} />
            </SectionContainer>
            <SectionContainer>
                <Spacer height={80} />
                <SectionTitle title="Épavite Transforme vos Soucis en Bénéfices" />
                <Spacer height={80} />
                <Grid gutter={40} maxColumns={3}>
                    <GridCell>
                        <IconCard
                            theme={theme}
                            iconURL={`assets/icons/icon-time-${theme}.svg`}
                            title="Dépannage Rapide sur la Route"
                            paragraph="Epavite vous offre une solution rapide sur la route, que ce soit pour le dépannage d'urgence, le remorquage, ou toute autre assistance routière. Notre réactivité assure une intervention rapide et efficace, à tout moment."
                        />
                    </GridCell>
                    <GridCell>
                        <IconCard
                            theme={theme}
                            iconURL={`assets/icons/icon-euro-${theme}.svg`}
                            title="Économisez avec notre Service de Dépannage"
                            paragraph="Choisir Epavite signifie opter pour l'efficacité sans compromis sur votre budget. Bénéficiez de tarifs compétitifs et économisez tout en profitant de services de qualité exceptionnelle pour le dépannage et le remorquage de votre véhicule."
                        />
                    </GridCell>
                    <GridCell>
                        <IconCard
                            theme={theme}
                            iconURL={`assets/icons/icon-smile-${theme}.svg`}
                            title="Simplicité en Cas d'Urgence Routière"
                            paragraph="Simplicité, du début à la fin. Avec Epavite, bénéficiez d'un service de dépannage et de remorquage sans tracas. Des solutions claires pour vous dépanner en toute simplicité, que ce soit pour un véhicule en panne, un remorquage nécessaire, ou toute autre situation sur la route."
                        />
                    </GridCell>
                </Grid>
                <Spacer height={140} />
            </SectionContainer>

            <SectionContainer theme="blue">
                <Spacer height={80} />
                <SectionTitle title="Besoin d'Aide sur la Route ? Consultez Nos Questions Fréquemment Posées !" />
                <Spacer height={80} />
                <div style={{ maxWidth: "var(--section-inner-width)", margin: "0 auto" }}>
                    <FaqCard
                        theme="blue"
                        question="Quels services de dépannage proposez-vous?"
                        answer="Nous offrons un ensemble complet de services de dépannage, couvrant les interventions d'urgence telles que le dépannage en cas de panne, le démarrage de batterie, la livraison de carburant et le remorquage."
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Quelle est la rapidité d'intervention en cas d'urgence?"
                        answer="Nous comprenons l'importance de la rapidité en situation d'urgence. Notre équipe s'efforce d'intervenir dans les plus brefs délais, généralement en moins de [indiquer le temps estimé] après votre appel."
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Quels types de véhicules pouvez-vous remorquer?"
                        answer="Nous sommes équipés pour remorquer une variété de véhicules, y compris les voitures, motos, VUS et petits utilitaires. Contactez-nous pour discuter de vos besoins spécifiques."
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Proposez-vous des services de dépannage 24/7?"
                        answer={`Oui, notre service de dépannage est disponible 24 heures sur 24, 7 jours sur 7. Que ce soit de jour comme de nuit, nous sommes là pour vous assister en cas d'urgence routière.`}
                    />
                    <Spacer height={20} />
                    <FaqCard
                        theme="blue"
                        question="Comment puis-je prendre rendez-vous pour un service de dépannage?"
                        answer={`Vous pouvez nous contacter par téléphone au +33 5 36 66 1000 pour planifier un service de dépannage. Nous sommes également disponibles via contact@epavite.fr pour toute demande d'information supplémentaire.`}
                    />
                </div>
                <Spacer height={140} />
            </SectionContainer>
            <FormSection theme={theme}
                hsForm={{
                    portalId: "143797396",
                    formId: "b9ac2092-b99f-49dd-9196-044ad9bd8b3c"
                }}
                formData={
                    [
                        { key: "firstname", title: "Prénom", type: "text", icon: "person" },
                        { key: "lastname", title: "Nom", type: "text", icon: "person" },
                        { key: "phone", title: "Numéro de téléphone", type: "text", icon: "phone" },
                        { key: "email", title: "Adresse mail", type: "text", icon: "mail" },
                        {
                            key: "service", title: "choisir un service", type: "select", icon: "car", options: [
                                // { title: "Enlèvement d'épave", value: "en-epave" },
                                { title: "Dépannage Batterie", value: "dep-batterie" },
                                { title: "Dépannage Crevaison", value: "dep-crevaison" },
                                { title: "Dépannage Carburant", value: "dep-carburant" },
                                { title: "Remorquage Véhicule", value: "rem-vehicule" },
                                { title: "Remorquage 2 Roues", value: "rem-2-roues" },
                            ]
                        }
                    ]
                }>
                <h2 className="text-style-header" style={{ color: "var(--blue-dark)" }}>
                    Demandez votre service de dépannage ou remorquage: appelez-nous ou remplissez le formulaire ci-après!
                </h2>
                <Spacer height={80} />
                <a href="tel: +33536661000" style={{ display: "block", width: "fit-content" }}>
                    <Button title="Appelez nous" theme={theme} />
                </a>
                <Spacer height={140} />
            </FormSection>
            {/* <SectionContainer>
                <Spacer height={80} />
                <Grid gutter={40} maxColumns={3}>
                    <GridCell>
                        <ReviewCard
                            theme='orange'
                            photoURL="assets/photos/bob-ross.jpg"
                            name="Bob Ross"
                            paragraph="Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, voluptates! Ratione, minus quas hic fugit odio itaque numquam accusamus, recusandae similique possimus obcaecati ipsum."
                        />
                    </GridCell>
                    <GridCell>
                        <ReviewCard
                            theme='orange'
                            photoURL="assets/photos/bob-ross.jpg"
                            name="Bob Ross"
                            paragraph="Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, voluptates! Ratione, minus quas hic fugit odio itaque numquam accusamus, recusandae similique possimus obcaecati ipsum."
                        />
                    </GridCell>
                    <GridCell>
                        <ReviewCard
                            theme='orange'
                            photoURL="assets/photos/bob-ross.jpg"
                            name="Bob Ross"
                            paragraph="Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, voluptates! Ratione, minus quas hic fugit odio itaque numquam accusamus, recusandae similique possimus obcaecati ipsum."
                        />
                    </GridCell>
                </Grid>
                <Spacer height={140} />
            </SectionContainer> */}
        </>
    )
}

export default LandingPage