import "./SectionContainer.scss";

function SectionContainer({ children, theme = "white" }) {
    const classList = ["ev-comp", "ev-comp-section-container", `theme-${theme}`];
    return (
        <div className={classList.join(" ")}>
            <div className="container">{children}</div>
        </div>
    )
}

export default SectionContainer