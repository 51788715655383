import { Fragment } from "react"
import "./Nav.scss"

function Nav({ linksData }) {
    const classList = ["ev-comp", "ev-comp-nav"]
    return (
        <div className={classList.join(" ")}>
            {
                linksData.map((link, index) => (
                    <Fragment key={index}>

                        {(index !== 0) && <img src="assets/slash/ev-slash-thin-white.svg" alt="" className="separator" />}
                        <a className="link text-style-nav-link" href={link.href}>
                            {link.title}
                        </a>
                    </Fragment>
                ))
            }
        </div>
    )
}

export default Nav